<template>
  <div>
    <ul class="flex flex-wrap items-center mb-6">
      <li class="inline-flex items-center">
        <router-link :to="`/${$route.params.lang}/${$store.state.AppActiveUser.userRole}/dashboard`" class="flex items-baseline">
          <feather-icon class="w-5 h-5 mr-2" icon="HomeIcon"></feather-icon>
        </router-link>
        <feather-icon class="w-4 h-4 mr-2" icon="ChevronsRightIcon"></feather-icon>
      </li>
      <li class="inline-flex items-center">
        <router-link :to="`/${$route.params.lang}/${$store.state.AppActiveUser.userRole}/orders/${$route.params.type}/`" class="mr-2">{{capitalizedType}}</router-link>
        <feather-icon class="w-4 h-4 mr-2"  icon="ChevronsRightIcon"></feather-icon>
        <span v-if="$route.params.type === 'exchanges'" >{{orderData.delivery_order.tracking_number}}</span>
        <span v-else>{{orderData.tracking_number}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import FeatherIcon from '../../../components/FeatherIcon.vue'

export default {
  props: ['orderData'],
  components: {
    FeatherIcon
  },
  data () {
    return {
      capitalizedType: ''
    }
  },
  created () {
    if (this.$route.params.type === 'delivery-orders') {
      this.capitalizedType = 'Delivery Orders'
    } if (this.$route.params.type === 'returns') {
      this.capitalizedType = 'Returns'
    } if (this.$route.params.type === 'exchanges') {
      this.capitalizedType = 'Exchanges'
    } if (this.$route.params.type === 'cash-collections') {
      this.capitalizedType = 'Cash Collections'
    }
  }
}
</script>
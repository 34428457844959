 <template>
  <div>
    <order-view-breadcrumb :orderData="exchangeOrder"/>
    <div class="w-full">
      <exchange-customer-info :cardLoading="cardLoading" :orderData="exchangeOrder"/>
    </div>
    <div class="w-full mt-6">
      <exchange-order :cardLoading="cardLoading" :outboundLoading="outboundLoading" :inboundLoading="inboundLoading" :inboundOrder="inboundOrder" :outboundOrder="outboundOrder" :orderData="exchangeOrder"/>
    </div>
  </div>
</template>

<script>
import ExchangeCustomerInfo from './ExchangeCustomerInfo.vue'
import ExchangeOrder from './ExchangeOrder.vue'
import {sendRequest, sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import OrderViewBreadcrumb from '../merchant-order-view/OrderViewBreadcrumb.vue'

export default {
  components: {
    ExchangeCustomerInfo,
    ExchangeOrder,
    OrderViewBreadcrumb
  },
  data () {
    return {
      exchangeOrder: {
        return_order: {},
        order_price: {
          total: 0,
          subtotal: 0,
          vat: 0,
          postal_tax: 0
        },
        delivery_order: {
          customer: {
            address: {
              zone: {
                city: {
                  governorate: {}
                }
              }
            }
          }
        }
      },
      inboundOrder: {},
      outboundOrder: {},
      cardLoading: false,
      outboundLoading: false,
      inboundLoading: false
    }
  },
  methods: {
    loadExchange () {
      this.cardLoading = true
      sendRequest(true, false, this, `api/v1/exchanges/${this.$route.params.orderID}/`, 'get', null, true, 
        (response) => {
          this.exchangeOrder = response.data
          if (response.data.delivery_order.fulfillment_provider_metadata) {
            this.loadInboundOrder(response.data.return_order.fulfillment_provider_metadata.shipblu.order)
            this.loadOutboundOrder(response.data.delivery_order.fulfillment_provider_metadata.shipblu.order)
          }
          this.cardLoading = false
        }
      )
    },
    loadInboundOrder (inboundOrderId) {
      this.inboundLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/inbound/${inboundOrderId}/`, 'get', null, true,
        (response) => {
          this.inboundOrder = response.data
          this.inboundLoading = false
        }, () => {}
      )
    },
    loadOutboundOrder (outboundOrderId) {
      this.outboundLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/outbound/${outboundOrderId}/`, 'get', null, true,
        (response) => {
          this.outboundOrder = response.data
          this.outboundLoading = false
        }, () => {}
      )
    }
  },
  created () {
    this.loadExchange()
  }
}
</script>